/* You can add global styles to this file, and also import other style files */
/* Light-blue Light scheme (Default) */
/* Can be forced with data-theme="light" */
[data-theme="light"],
:root:not([data-theme="dark"]) {
  --primary: #039be5;
  --primary-hover: #0288d1;
  --primary-focus: rgba(3, 155, 229, 0.125);
  --primary-inverse: #FFF;
}

/* Light-blue Dark scheme (Auto) */
/* Automatically enabled if user has Dark mode enabled */
@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme="light"]) {
    --primary: #039be5;
    --primary-hover: #03a9f4;
    --primary-focus: rgba(3, 155, 229, 0.25);
    --primary-inverse: #FFF;
  }
}

/* Light-blue Dark scheme (Forced) */
/* Enabled if forced with data-theme="dark" */
[data-theme="dark"] {
  --primary: #039be5;
  --primary-hover: #03a9f4;
  --primary-focus: rgba(3, 155, 229, 0.25);
  --primary-inverse: #FFF;
}

/* Light-blue (Common styles) */
:root {
  --form-element-active-border-color: var(--primary);
  --form-element-focus-color: var(--primary-focus);
  --switch-color: var(--primary-inverse);
  --switch-checked-background-color: var(--primary);
}
